var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { remToNumber } from '../../utils';
import { ErrorText } from '../ErrorText';
import { FormGroup } from '../FormGroup';
import { HintText } from '../HintText';
import { NumberInput } from '../Input';
import { LabelText } from '../LabelText';
import { Stack } from '../Stack';
import { SubLabelText } from '../SubLabelText';
export var InlineFields = styled.div(function (_a) {
    var _b = _a.theme, field = _b.field, mediaQueries = _b.mediaQueries, _c = _a.fullWidth, fullWidth = _c === void 0 ? 'small' : _c;
    var halfRem = function (value) { return remToNumber(value) / 2 + 'rem'; };
    var margin = halfRem(field.gap);
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n\n    margin-left: -", ";\n    margin-right: -", ";\n    > * {\n      margin-left: ", ";\n      margin-right: ", ";\n    }\n\n    ", "\n  "], ["\n    display: flex;\n\n    margin-left: -", ";\n    margin-right: -", ";\n    > * {\n      margin-left: ", ";\n      margin-right: ", ";\n    }\n\n    ", "\n  "])), margin, margin, margin, margin, fullWidth === 'small'
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          ", " {\n            width: ", ";\n          }\n        "], ["\n          ", " {\n            width: ", ";\n          }\n        "])), mediaQueries.smallAndUp, field.dateWidth) : fullWidth === 'never'
        ? "\n            width: ".concat(field.dateWidth, ";\n          ")
        : '');
});
var DayField = styled(Stack)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 25%;\n"], ["\n  width: 25%;\n"])));
var MonthField = styled(Stack)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 25%;\n"], ["\n  width: 25%;\n"])));
var YearField = styled(Stack)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 50%;\n"], ["\n  width: 50%;\n"])));
var DatePart = styled.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var DateField = forwardRef(function (_a, ref) {
    var id = _a.id, name = _a.name, label = _a.label, hint = _a.hint, error = _a.error, _b = _a.optional, optional = _b === void 0 ? false : _b, _c = _a.fullWidth, fullWidth = _c === void 0 ? 'small' : _c, _d = _a.readOnly, readOnly = _d === void 0 ? false : _d, value = _a.value, defaultValue = _a.defaultValue, onChange = _a.onChange, dataTestId = _a["data-testid"];
    var hasError = !!error;
    var handleChange = function (key) { return function (event) {
        var _a;
        onChange &&
            value &&
            onChange(__assign(__assign({}, value), (_a = {}, _a[key] = event.target.value, _a)));
    }; };
    var monthId = "".concat(id, "-month");
    var yearId = "".concat(id, "-year");
    return (React.createElement(FormGroup, { "data-error": hasError || undefined },
        React.createElement(Stack, { as: "fieldset", spaceBetween: 1 },
            React.createElement("legend", null,
                React.createElement(LabelText, null,
                    label,
                    optional ? ' (optional)' : null),
                hint ? React.createElement(HintText, null, hint) : null,
                error ? React.createElement(ErrorText, null, error) : null),
            React.createElement(InlineFields, { fullWidth: fullWidth },
                React.createElement(DayField, { spaceBetween: 1 },
                    React.createElement(SubLabelText, { as: "label", htmlFor: id }, "Day"),
                    React.createElement(DatePart, { as: NumberInput, ref: ref, id: id, "data-testid": dataTestId, name: name, "aria-invalid": hasError || undefined, value: value && value.day, defaultValue: defaultValue && defaultValue.day, readOnly: readOnly, onChange: handleChange('day') })),
                React.createElement(MonthField, { spaceBetween: 1 },
                    React.createElement(SubLabelText, { as: "label", htmlFor: monthId }, "Month"),
                    React.createElement(DatePart, { as: NumberInput, id: monthId, "data-testid": dataTestId && "".concat(dataTestId, "-month"), name: name && "".concat(name, "-month"), "aria-invalid": hasError || undefined, value: value && value.month, defaultValue: defaultValue && defaultValue.month, readOnly: readOnly, onChange: handleChange('month') })),
                React.createElement(YearField, { spaceBetween: 1 },
                    React.createElement(SubLabelText, { as: "label", htmlFor: yearId }, "Year"),
                    React.createElement(DatePart, { as: NumberInput, id: yearId, "data-testid": dataTestId && "".concat(dataTestId, "-year"), name: name && "".concat(name, "-year"), "aria-invalid": hasError || undefined, value: value && value.year, defaultValue: defaultValue && defaultValue.year, readOnly: readOnly, onChange: handleChange('year') }))))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
